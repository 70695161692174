export function productDetail(color, weight, density, spread, dry, packaging, diluent, tools, glossLevel) {
    return {
        color: color,
        gloss_level: glossLevel,
        weight: weight,
        density: density,
        spread: spread,
        dry: dry,
        packaging: packaging,
        diluent: diluent,
        tools: tools,
    };
}

export function productData(name, imageUrl, badge, benefits, detail, description, usage, color, description2, readyMixUrl, shopeeUrl) {
    return {
        data: {
            name: name,
            imageUrl: imageUrl,
            badge: badge,
            benefits: benefits,
            detail: detail,
            description: description,
            usage: usage,
            color: color,
            description2: description2,
            readyMixUrl: readyMixUrl,
            shopeeUrl: shopeeUrl
        }
    };
}
