<template>
  <header>
  </header>
  <main>
    <div class="app-container">
      <div class="app-content-container" ref="appContent">
        <HeaderPage v-if="!shouldHideHeader" :key="$route.path"/>
        <router-view/>
        <FooterPage v-if="!shouldHideFooter" :key="$route.path"/>
      </div>
      <FirstMountedOverlay v-if="showOverlay"/>
    </div>
  </main>
</template>

<script>
import HeaderPage from "@/components/global/header/HeaderGlobal.vue";
import FooterPage from "@/components/global/footer/FooterPage.vue";
import FirstMountedOverlay from "@/components/overlay/first_mounted_overlay/FirstMountedOverlay.vue";
import {images} from "@/assets/assets";

export default {
  name: 'App',
  components: {
    FirstMountedOverlay,
    HeaderPage,
    FooterPage,
  },
  data() {
    return{
      shouldHideHeader: false,
      shouldHideFooter: false,
      showOverlay: false,
    }
  },
  mounted() {
    this.hideComponent()
    this.preLoadImages()
    const firstMounted = sessionStorage.getItem('isFirstMounted')
    if (!firstMounted) {
      this.showOverlay = true
      setTimeout(() => {
        this.$refs.appContent.setAttribute('active', '')
        sessionStorage.setItem('isFirstMounted', 'true')
      }, 3000)
    } else {
      setTimeout(() => {
        this.$refs.appContent.setAttribute('active', '')
      }, 0)
    }
  },
  watch: {
    $route(to) {
      this.hideComponent(to)
    }
  },
  methods: {
    hideComponent(route = this.$route) {
      this.shouldHideFooter = route.path === '/404' || route.path === '/contactUs'
      this.shouldHideHeader = route.path === '/404'
    },
    preLoadImages() {
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      })
    },
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  background-color: black;
}

.app-container {
  width: 100%;
  height: auto;
  overflow-x: hidden;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
}

.app-content-container {
  opacity: 0;
  transition: opacity 500ms ease;
}

.app-content-container[active] {
  opacity: 1;
}

@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes showFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes hideToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
