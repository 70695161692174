<template>

  <div class="first-mounted-overlay-container" ref="firstMountedOverlayContainer">
    <div class="first-mounted-overlay-spinner"></div>
    <p class="first-mounted-overlay-container-text">Loading...</p>
  </div>

</template>

<script>

export default {
  name: 'FirstMountedOverlay',
  data() {
    return {
      visible: true
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    document.body.addEventListener('touchmove', this.preventScroll);
    setTimeout(() => {
      this.$refs.firstMountedOverlayContainer.setAttribute('active', '')
      setTimeout(() => {
        this.$refs.firstMountedOverlayContainer.removeAttribute('active')
        setTimeout(() => {
          this.$refs.firstMountedOverlayContainer.style.display = 'none'
          document.body.style.overflow = ''
          document.body.removeEventListener('touchmove', this.preventScroll);
        }, 500)
      }, 2500)
    }, 0)
  },
  methods: {
    preventScroll(event) {
      event.preventDefault();
    }
  }
}

</script>

<style scoped>
.first-mounted-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
.first-mounted-overlay-container[active] {
  opacity: 1;
}
.first-mounted-overlay-spinner {
  border: 6px solid var(--dodger-blue);
  border-top: 6px solid var(--white);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}
.first-mounted-overlay-container-text {
  font-weight: 500;
  font-size: 32px;
  color: var(--white);
}
</style>
