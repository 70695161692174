<template>
  <div ref="productHighlightContainer" class="product-highlight-container">
    <div class="product-highlight-top-content-container">
      <div class="product-highlight-header-left">
        <p class="product-highlight-big-text">Transform Your Ride</p>
        <p class="product-highlight-small-text">Elevate Your Car’s Aesthetics with Our Premium Coating Solutions!</p>
        <p v-if="shouldHideProductHighlightHeaderRight" class="product-highlight-smallest-text" style="margin-bottom: unset">Our coating products are made from a range of materials to suit different needs. These include Nitrocellulose for a smooth finish, Polyurethane for durability, and Epoxy for strength and chemical resistance. We also offer Epoxy Polyurethane blends for extra performance, clear coats for shine, wood coatings for natural surfaces, primers for protection, and zinc coatings for corrosion resistance. Each product ensures high quality and lasting protection.</p>
      </div>
      <div v-if="!shouldHideProductHighlightHeaderRight" class="product-highlight-header-right">
        <p class="product-highlight-smallest-text-on-right add-margin-top">Our coating products are made from a range of materials to suit different needs. These include Nitrocellulose for a smooth finish, Polyurethane for durability, and Epoxy for strength and chemical resistance. We also offer Epoxy Polyurethane blends for extra performance, clear coats for shine, wood coatings for natural surfaces, primers for protection, and zinc coatings for corrosion resistance. Each product ensures high quality and lasting protection.</p>
      </div>
    </div>
    <div class="product-highlight-bottom-content-container">
      <div class="product-highlight-bottom-content">
      <button :disabled="shouldDisableLeftArrow" @click="showPrevCards(true)" class="prev-button" ref="prevButton"></button>
        <div class="product-highlight-scroll-container" ref="scrollContainer">
          <div class="product-highlight-card" v-for="(item, index) in array" :key="index">
            <div class="card-top-section">
                <div v-if="item.data.badge === 'Best Seller'" class="card-badge-best-seller"></div>
                <div class="grey-background"></div>
                <div class="product-highlight-image">
                  <img :src="item.data.imageUrl" alt="Product Image" @click="seeMore(item.data.category, item.data.productName, this.$refs.productImage[index])" ref="productImage"/>
                </div>
              </div>
              <div class="card-bottom-section">
                <div class="card-bottom-content-container">
                  <p class="product-name">{{ item.data.name }}</p>
                  <p class="product-description">{{ item.data.description }}</p>
                </div>
                <button class="card-button" @click="seeMore(item.data.category, item.data.productName, this.$refs.seeDetail[index])" ref="seeDetail">See Detail</button>
              </div>
          </div>
        </div>
        <button :disabled="shouldDisableRightArrow" @click="showNextCards(true)" class="next-button" ref="nextButton"></button>
      </div>
      <button class="product-highlight-explore-button" ref="exploreButton" @click="exploreAll">
        Explore All Products
      </button>
    </div>
  </div>
</template>
<script>


function cardData(name, imageUrl, badge, description, category, productName) {
  return {
    data: { name: name,
      imageUrl: imageUrl,
      showDetail: false,
      description: description,
      badge: badge,
      category: category,
      productName: productName
    }
  }
}
export default {
  name: "ProductHighlightPage",
  data () {
    return {
      array: [
        cardData( "Clear S2", "https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155231_616.png", null,  "High Solid Clear yang sangat glossy dan tahan lama", 'clearCoatReducer', 'Clear_S2'),
        cardData( "2K PU Surfacer", "https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_083.png", null,   "Pelapis primer 2 komponen yang cepat kering, keras dan mudah diaplikasikan", 'primerSurfacer', 'Surfacer_PU_2K'),
        cardData( "Clear 705", "https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_633.png", null,   "Medium Solid Clear yang glossy dan tahan lama", 'clearCoatReducer', 'Clear_705'),
        cardData("Zinc Chromate", "https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_657.png", null,  "Pelapis primer sintetik dengan hasil yang lebih halus, anti korosi dan solusi terbaik untuk efisiensi maksimal", 'zincChromate', 'Zinc_Chromate'),
        cardData("PP Primer Surfacer", "https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_714.png", null,  "Pelapis primer untuk Plastik (PE) yang kuat dan cepat kering, tidak mudah retak dan terkelupas", 'ppPrimerSurfacer', 'PP_Primer_Surfacer_-_Light_Grey'),
        cardData( "Epoxy Primer Green", "https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_932.png", "Best Seller",  "Pelapis primer untuk Plat Metal yang kuat dan cepat kering, mudah diaplikasikan dan daya tutup tinggi", 'primerSurfacer', 'Epoxy_Primer_Green')
      ],
      shouldHideProductHighlightHeaderRight: false,
      shouldDisableRightArrow: false,
      shouldDisableLeftArrow: false,
      cardWidth: 260,
      isLargeScreen: true,
      isScrolling: false,
      showModal: false,
      selectedData: null
    }
  },
  mounted() {
    if (window.innerWidth <= 960) {
      this.isLargeScreen = false
      this.shouldHideProductHighlightHeaderRight = true
    }
    window.addEventListener('resize', this.updateVisibleArray);
    this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
    this.shouldDisableLeftArrow = this.$refs.scrollContainer.scrollLeft === 0
  },
  beforeUnmount() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateVisibleArray)
  },
  methods: {
    scrollToLeft() {
      this.$nextTick( () => {
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollLeft = 0;
        }
      })
    },
    showNextCards() {
      if (this.isScrolling) return;

      this.isScrolling = true;
      this.$refs.nextButton.setAttribute('active', '')

      this.$refs.scrollContainer.scrollBy({
        left: this.cardWidth,
        behavior: 'smooth'
      });

      setTimeout(() => {
        this.isScrolling = false;
        this.$refs.nextButton.removeAttribute('active')
      }, 300);
    },
    showPrevCards() {
      if (this.isScrolling) return;

      this.isScrolling = true;
      this.$refs.prevButton.setAttribute('active', '')

      this.$refs.scrollContainer.scrollBy({
        left: -this.cardWidth,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.isScrolling = false;
        this.$refs.prevButton.removeAttribute('active')
      }, 300);
    },
    updateVisibleArray() {
      if (window.innerWidth <= 960 && this.isLargeScreen) {
        this.isLargeScreen = false
        this.shouldHideProductHighlightHeaderRight = true
        this.scrollToLeft()
      } else if (window.innerWidth > 960 && !this.isLargeScreen) {
        this.isLargeScreen = true
        this.shouldHideProductHighlightHeaderRight = false
        this.scrollToLeft()
      }
    },
    seeMore(category, name, ref) {
      ref.setAttribute('clicked', '')
      setTimeout(() => {
        ref.removeAttribute('clicked')
        this.$router.push(`/productList?category=${category}&name=${name}`)
      }, 300)
    },
    closeModal() {
      this.showModal = false;
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      this.shouldDisableRightArrow = container.scrollLeft >= maxScrollLeft;
      this.shouldDisableLeftArrow = container.scrollLeft === 0;
    },
    exploreAll() {
      this.$refs.exploreButton.setAttribute('clicked', '')
      setTimeout(() => {
        this.$refs.exploreButton.removeAttribute('clicked')
        this.$router.push('/productList')
      }, 300)
    }
  }
}

</script>
<style>
.product-highlight-container {
  width: 100%;
  height: 100vh;
  height: 100lvh;
  max-height: 1000px;
  min-height: 667px;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  border: none;
  background-color: black;
}
.product-highlight-top-content-container {
  background: linear-gradient(to top, grey, black);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 30%;
  @media (--large) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 40%;
  }
}
.product-highlight-header-left {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @media (--large) {
    background: linear-gradient(to top, grey, black);
    justify-content: center;
    width: 50%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.product-highlight-header-right {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, grey, black);
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (--large) {
    width: 45%;
    height: 100%;
  }
}
.product-highlight-big-text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 40px;
  color: var(--white);
  @media (--large) {
    font-size: 60px;
  }
}
.product-highlight-small-text {
  font-size: 16px;
  color: wheat;
  @media (--large) {
    margin-bottom: unset;
    font-size: 22px;
  }
}
.product-highlight-smallest-text {
  margin-top: 10px;
  color: var(--white);
  width: 80%;
  font-size: 12px;
  margin-bottom: 60px;
}
.product-highlight-smallest-text-on-right {
  font-size: 12px;
  width: 80%;
  color: white;
  text-align: left;
  margin-bottom: 15px;
  @media (--large) {
    margin-bottom: unset;
    font-size: 18px;
  }
}
.product-highlight-bottom-content-container {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-highlight-bottom-content {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  width: 100%;
  height: auto;
}
.product-highlight-scroll-container {
  box-sizing: border-box;
  display: flex;
  width: 260px;
  height: 350px;
  overflow-x: scroll;
  scrollbar-width: none;
  padding-right: 10px;
  padding-left: 10px;
  gap: 20px;
  scroll-snap-type: x mandatory;
  @media (--large) {
    width: 780px;
  }
}
.product-highlight-scroll-container::-webkit-scrollbar {
  display: none;
}
.product-highlight-card {
  scroll-snap-align: center;
  flex-shrink: 0;
  width: 240px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-top-section {
  position: relative;
  width: 100%;
  height: 50%;
  background-color: var(--white);
}
.product-highlight-image {
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 160px;
  height: 160px;
  z-index: 1;
}
.product-highlight-image img {
  background-position: center;
  cursor: pointer;
  width: 100%;
  object-fit: contain;
  transition: scale 300ms ease;
  @media (--large) {
    &:hover {
      scale: 1.1;
    }
  }
}
.product-highlight-image img[clicked] {
  animation: clickAnimation 300ms ease;
}
.card-badge-best-seller {
  position: absolute;
  top: 0;
  right: 2px;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/best-seller%20(2).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.grey-background {
  position: relative;
  width: 100%;
  height: 200px;
  border-top: 60px var(--white) solid;
  border-left: 40px var(--white) solid;
  border-right: 40px var(--white) solid;
  border-bottom: 50px var(--white) solid;
  background-color: lightgrey;
  opacity: 20%;
  box-sizing: border-box;
  z-index: 0;
}
.card-bottom-section {
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, var(--white), #fdf6e3);
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.card-bottom-content-container {
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(to bottom, var(--white), #fdf6e3);
}
.product-name {
  margin-top: 10px;
  font-size: 32px;
  font-weight: 500;
}
.product-description {
  margin-top: 5px;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 400;
  width: 90%;
}
.card-button {
  margin-top: auto;
  margin-bottom: 0;
  background-color: #fdf6e3;
  width: 100%;
  height: 30px;
  border: none;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  transition: background-color 300ms ease, color 300ms ease;
  @media (--large) {
    &:hover {
      color: var(--white);
      background-color: var(--dodger-blue);
    }
  }
}
.card-button[clicked] {
  background-color: var(--corn-flower-blue);
}
.prev-button {
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-95-512.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover:enabled {
      scale: 1.1;
    }
  }
  &:disabled {
    cursor: default;
    filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(125%) hue-rotate(314deg) brightness(92%) contrast(85%);
  }
}
.prev-button[active] {
  animation: clickAnimation 300ms ease;
}
.next-button {
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-30-512.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover:enabled {
      scale: 1.1;
    }
  }
  &:disabled {
    cursor: default;
    filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(125%) hue-rotate(314deg) brightness(92%) contrast(85%);
  }
}
.next-button[active] {
  animation: clickAnimation 300ms ease;
}
.product-highlight-explore-button {
  margin-top: 30px;
  padding: 10px 20px;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  color: #fff;
  border-radius: 7px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  z-index: 1;
  cursor: pointer;
}

.product-highlight-explore-button {
  background: rgb(82, 113, 255);
  box-shadow: 0 0 25px rgb(82, 113, 255);
}

.product-highlight-explore-button:hover {
  box-shadow: 0 0 5px rgb(82, 113, 255),
  0 0 25px rgb(82, 113, 255),
  0 0 50px rgb(82, 113, 255),
  0 0 100px rgb(82, 113, 255);
}
.product-highlight-explore-button[clicked] {
  animation: clickAnimation 300ms ease;
}
</style>
