<template>
  <div class="social-media-container-outer">
    <section class="social-media-container-inner" ref="socialMediaContainerInner">
      <section class="social-media-top-container">
        <h1 class="social-media-title">Our Social Media</h1>
        <div class="social-media-list-container">
          <img class="social-media-logo social-media-logo-wa" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/whatsapp%20ori.png" alt="wa" ref="wa" @click="redirection('https://wa.me/6285159122501?text=Chat%20with%20our%20admin', this.$refs.wa)">
          <img class="social-media-logo" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/instagram.png" alt="ig" ref="ig" @click="redirection('https://www.instagram.com/dumons.id?igsh=MWRxZW9ibTFnMm55NQ==', this.$refs.ig)">
          <img class="social-media-logo" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/tik-tok.png" alt="tt" ref="tt" @click="redirection('https://www.tiktok.com/@dumons.id?_t=8qa0g3A4H90&_r=1', this.$refs.tt)">
          <img class="social-media-logo social-media-logo-shopee" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/pngwing.com%20(5).png" alt="sp" ref="sp" @click="redirection('https://shopee.co.id/dumonsindonesia', this.$refs.sp)">
        </div>
      </section>
      <section class="social-media-bottom-container">
        <div class="social-media-loading-overlay" ref="loadingOverlay"></div>
        <div class="social-media-image-gallery-container" ref="galleryContainer">
          <div class="social-media-image-gallery">
            <div v-for="(content, index) in contents" :key="index">
              <img
                  v-if="content.data.type === 'image'"
                  class="social-media-image"
                  :src="content.data.url"
                  alt="images"
                  ref="imageRef"
                  @click="openImageModal(content.data.url)"
              >
              <video v-if="content.data.type === 'video'" class="social-media-image" :src="content.data.url" controls ref="imageRef"></video>
            </div>
          </div>
        </div>
<!--        <button class="social-media-load-more" @click="loadMore" ref="loadMore">Load more</button>-->
      </section>
    </section>
    <LoadingOverlay v-if="isLoading" :should-hide-overlay="shouldHideOverlay" :is-visible="isLoading" :timeout="[400, 1000]"/>
    <ImageModal v-if="showImage" :image-url="selectedImageUrl" @closeModal="closeImageModal" />
  </div>
</template>

<script>
import LoadingOverlay from "@/components/overlay/loading_overlay/LoadingOverlay.vue";
import ImageModal from "@/components/modal/image_modal/ImageModal.vue";
import {data} from "autoprefixer";

function content(url, type) {
  return {
    data: {
      url: url,
      type: type
    }
  }
}

export default {
  name: "SocialMediaPage",
  computed: {
    data() {
      return data
    }
  },
  components: {ImageModal, LoadingOverlay},
  data () {
    return {
      isLoading: false,
      contents: [],
      allContents: [
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/1.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/2.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/3.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/bluetruck.jpg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.21.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(1).jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(2).jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/1.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/2.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/3.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/bluetruck.jpg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.21.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(1).jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(2).jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/1.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/2.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/3.png", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/bluetruck.jpg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.21.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(1).jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(2).jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.23.jpeg", "image"),
        content("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/main/videos/Snapinsta.app_video_704B9A00E10D57A94E461EFADDE9A2AF_video_dashinit.mp4", "video"),
      ],
      index: 10,
      disableClick: false,
      showImage: false,
      selectedImageUrl: null
    }
  },
  mounted() {
    // this.contents = this.allContents.slice(0, 10)
    this.contents = this.allContents
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.socialMediaContainerInner.setAttribute('active', '')
        this.$refs.imageRef.forEach((each) => each.setAttribute('active', ''))
        setTimeout(() => {
          if (this.$refs.loadingOverlay) this.$refs.loadingOverlay.setAttribute('hide', '')
          if (this.$refs.galleryContainer) this.$refs.galleryContainer.setAttribute('active', '')
        }, 3000)
      }, 0)
    })
  },
  methods: {
    redirection(redirectUrl, ref) {
      ref.setAttribute('clicked', '')
      setTimeout(() => {
        window.open(redirectUrl)
        ref.removeAttribute('clicked')
      }, 310)
    },
    loadMore() {
      if (this.contents[this.contents.length - 1].data.url === this.allContents[this.allContents.length - 1].data.url) return
      if (this.disableClick === true) return
      this.disableClick = true
      this.$refs.loadMore.setAttribute('clicked', '')
      this.isLoading = true
      this.contents = this.contents.concat(this.allContents.slice(this.index, this.index + 10))
      this.index = this.index + 10
      setTimeout(() => {
        this.$refs.imageRef.forEach((each) => each.setAttribute('active', ''))
        this.disableClick = false
        this.$refs.loadMore.removeAttribute('clicked')
        if (window.innerWidth > 715) {
          this.$refs.galleryContainer.scrollTo({top: 0, behavior: 'smooth'})
          window.scrollTo({top: 0, behavior: 'smooth'})
        }
      }, 400)
    },
    shouldHideOverlay(value) {
      this.isLoading = value
    },
    openImageModal(url) {
      if (window.innerWidth <= 430) {
        this.showImage = true
        this.selectedImageUrl = url
      }
    },
    closeImageModal() {
      this.showImage = false
    }
  }
}
</script>

<style>
.social-media-container-outer {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  min-width: 320px;
  max-height: 1000px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  transition: height 1000ms ease;
}
.social-media-container-inner {
  opacity: 0;
  height: 100%;
  width: 100%;
  min-height: 600px;
  min-width: 320px;
  max-height: 1000px;
  max-width: 2000px;
  background-color: black;
  transition: opacity 500ms ease, height 1000ms ease;
}
.social-media-container-inner[active] {
  opacity: 1;
}
.social-media-top-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  box-sizing: border-box;
  background-color: black;
  border-bottom: solid var(--dodger-blue) 10px;
}
.social-media-title {
  color: var(--white);
  font-size: 32px;
  margin-bottom: 0;
  font-family: "Bebas Neue", sans-serif;
  @media (--large) {
    font-size: 42px;
  }
}
.social-media-list-container {
  box-sizing: border-box;
  margin-top: -10px;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 10px;
}
.social-media-logo {
  margin-top: 8px;
  width: 40px;
  height: 40px;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover {
      scale: 1.1;
      cursor: pointer;
    }
  }
}
.social-media-logo[clicked] {
  animation: clickAnimation 300ms ease;
}
.social-media-logo-shopee {
  margin-top: 6px;
  width: 46px;
  height: 47px;
  margin-left: -7px;
}
.social-media-logo-wa {
  margin-right: 2px;
}
.social-media-bottom-container {
  width: 100%;
  height: 80%;
  background-color: lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.social-media-loading-overlay {
  position: absolute;
  border: 6px solid var(--dodger-blue);
  border-top: 6px solid var(--white);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}
.social-media-loading-overlay[hide] {
  transition: opacity 500ms ease;
  opacity: 0;
}
.social-media-image-gallery-container {
  opacity: 0;
  box-sizing: border-box;
  padding-right: 50px;
  padding-left: 50px;
  width: 100%;
  height: 96%;
  overflow-y: scroll;
  @media (--large) {
    padding-right: 100px;
    padding-left: 100px;
  }
}
.social-media-image-gallery-container[active] {
  transition: opacity 500ms ease;
  opacity: 1;
}

.social-media-image-gallery {
  columns: 300px;
}

.social-media-image {
  opacity: 0;
  width: 100%;
  margin-top: 0.53rem;
  margin-bottom: 0.27rem;
  transition: opacity 400ms ease-in;
}
.social-media-image[active] {
  opacity: 1;
}
.social-media-load-more {
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  font-weight: 400;
  bottom: 1.3%;
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: color 300ms ease, scale 300ms ease;
  @media (--large) {
    &:hover{
      color: var(--dodger-blue);
      scale: 1.1;
    }
  }
}
.social-media-load-more[clicked] {
  animation: clickAnimation 300ms ease;
}
</style>
