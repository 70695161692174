<template>
  <div ref="notFoundContainer" class="not-found-container">
    <p class="not-found-title">404 Page Not Found</p>
    <button class="back-to-home" ref="home" @click="backToHome">Back to home</button>
  </div>
</template>

<script>

export default {
  name: 'NotFoundPage',
  mounted() {
  },
  methods: {
    backToHome() {
      this.$refs.home.setAttribute('clicked', '')
      setTimeout(() => {
        this.$refs.home.removeAttribute('clicked')
        this.$router.push('/home')
      }, 300)
    }
  }
}

</script>

<style>
.not-found-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100lvh;
  max-height: 1000px;
  min-height: 600px;
  min-width: 320px;
  background-color: var(--white);
  align-items: center;
  justify-content: center;
}
.not-found-title {
  font-size: 42px;
}
.back-to-home {
  background-color: transparent;
  width: 100px;
  height: 40px;
  color: black;
  border: none;
  transition: scale 300ms ease;
  font-family: "Roboto Condensed", sans-serif;
  will-change: transform;
  @media (--large) {
    &:hover {
      cursor: pointer;
      scale: 1.2;
    }
  }
}
.back-to-home[clicked] {
  animation: clickAnimation 300ms ease;
}
</style>
