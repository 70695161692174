<template>

  <div v-if="isVisible" class="loading-overlay" ref="loadingOverlay">
    <div class="loading-spinner"></div>
    <p class="loading-text">Loading...</p>
  </div>

</template>

<script>

export default {
  name: 'LoadingOverlay',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    shouldHideOverlay: {
      type: Function,
      default: null
    },
    timeout: {
      type: Array,
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.loadingOverlay.setAttribute('active', '')
      setTimeout(() => {
        this.$refs.loadingOverlay.removeAttribute('active')
        setTimeout(() => {
          this.shouldHideOverlay(false)
        },this.$props.timeout[0])
      }, this.$props.timeout[1])
    },0)
  }
}

</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
.loading-overlay[active] {
  opacity: 1;
}
.loading-spinner {
  border: 6px solid var(--dodger-blue);
  border-top: 6px solid var(--white);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}
.loading-text {
  font-weight: 500;
  font-size: 32px;
  color: var(--white);
}
</style>
