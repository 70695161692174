<template>
  <section class="contact-us-outer-container">
    <section class="contact-us-inner-container" ref="contactUsInnerContainer">
      <section class="contact-us-top-container">
        <h1 class="contact-us-title">Get in touch</h1>
        <h1 class="contact-us-title">With us</h1>
      </section>
      <section class="contact-us-bottom-container">
        <div class="contact-us-scroll-container">
          <div class="contact-us-card-container" ref="contactUsCardContainer">
            <div class="contact-us-card">
              <p class="contact-us-address-title">Address</p>
              <img class="contact-us-address-logo" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/gps%20(1).png" alt="location">
              <p class="contact-us-address">Jl Raya Boyolali - Semarang km 0.2 Winong, Boyolali, Jawa Tengah, Indonesia 57315</p>
              <button class="contact-us-address-button" ref="googleMaps" @click="redirection('https://maps.app.goo.gl/AmS1U8RRRzUTA8Ew6', this.$refs.googleMaps)">Google Maps</button>
            </div>
            <div class="contact-us-card">
              <p class="contact-us-cs-title">Customer Support</p>
              <img class="contact-us-cs-logo" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/customer-service%20(1).png" alt="cs">
              <p class="contact-us-phone">Phone</p>
              <p class="contact-us-phone-number">(0276) 334 0069</p>
              <p class="contact-us-whatsapp">Whatsapp</p>
              <p class="contact-us-whatsapp-number">085159122501</p>
              <button class="contact-us-wa-button" ref="wa" @click="redirection('https://wa.me/6285159122501?text=Chat%20with%20our%20admin', this.$refs.wa)">Contact Support</button>
            </div>
            <div class="contact-us-card">
              <p class="contact-us-email-title">EMAIL</p>
              <img class="contact-us-email-logo" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/mail%20(1).png" alt="email">
              <p class="contact-us-email">Admin & Career</p>
              <p>dumonscoatingindonesia@gmail.com</p>
            </div>
          </div>
          <div v-if="shouldShowDots" class="contact-us-dots-container">
            <div v-for="(dot, index) in dots" :key="index" class="contact-us-dot" ref="dot"></div>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ContactUsPage',
  data() {
    return {
      dots: 3,
      shouldShowDots: false,
      resize: false
    }
  },
  methods: {
    redirection(redirectUrl, ref) {
      ref.setAttribute('clicked', '')
      setTimeout(() => {
        window.open(redirectUrl)
        ref.removeAttribute('clicked')
      }, 310)
    },
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|iphone|ipad|ipod|windows phone|opera mini|iemobile/i.test(userAgent.toLowerCase());
    },
    updateDots() {
      if (this.resize || !this.isMobile()) return
      const container = this.$refs.contactUsCardContainer
      const maxScroll = container.scrollWidth - container.clientWidth
      if (container.scrollLeft === 0) {
        this.$refs.dot[0].setAttribute('active', '')
        this.$refs.dot[1].removeAttribute('active')
        this.$refs.dot[2].removeAttribute('active')
      } else if (container.scrollLeft === maxScroll) {
        this.$refs.dot[0].removeAttribute('active')
        this.$refs.dot[1].removeAttribute('active')
        this.$refs.dot[2].setAttribute('active', '')
      } else if (container.scrollLeft === 320) {
        this.$refs.dot[0].removeAttribute('active')
        this.$refs.dot[1].setAttribute('active', '')
        this.$refs.dot[2].removeAttribute('active')
      }
    },
    handleResize() {
      if (!this.isMobile() && window.innerWidth < 768) {
        this.$refs.contactUsCardContainer.style.scrollbarWidth = "thin"
        this.$refs.contactUsCardContainer.style.scrollbarColor = "var(--black) var(--white)"
        if (this.$refs.dot) this.$refs.dot.forEach(v => v.removeAttribute('active'))
        this.shouldShowDots = false
      } else if (!this.isMobile() && window.innerWidth >= 768) {
        this.$refs.contactUsCardContainer.style.scrollbarWidth = "none"
        this.$refs.contactUsCardContainer.style.scrollbarColor = "none"
        if (this.$refs.dot) this.$refs.dot.forEach(v => v.removeAttribute('active'))
        this.shouldShowDots = false
      } else if (this.isMobile() && innerWidth < 768 && this.shouldShowDots === false) {
        this.$refs.contactUsCardContainer.style.scrollbarWidth = "none"
        this.$refs.contactUsCardContainer.style.scrollbarColor = "none"
        this.resize = false
        this.shouldShowDots = true
        setTimeout(() => {
          this.$refs.dot[0].setAttribute('active', '')
          this.$refs.dot[1].removeAttribute('active')
          this.$refs.dot[2].removeAttribute('active')
        },0 )
        this.$refs.contactUsCardContainer.scrollTo({ left: 0 , behavior: 'smooth' })
      } else if (this.isMobile() && innerWidth >= 768 && this.shouldShowDots === true) {
        this.$refs.contactUsCardContainer.style.scrollbarWidth = "none"
        this.$refs.contactUsCardContainer.style.scrollbarColor = "none"
        this.resize = true
        this.shouldShowDots = false
        this.$refs.dot.forEach(v => v.removeAttribute('active'))
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.$refs.contactUsCardContainer.addEventListener('scroll', this.updateDots)
    setTimeout(() => {
      this.$refs.contactUsInnerContainer.setAttribute('active', '')
      if (this.shouldShowDots === true) this.$refs.dot[0].setAttribute('active', '')
    }, 0)
    if (!this.isMobile()) {
      this.$refs.contactUsCardContainer.style.scrollbarWidth = "thin"
      this.$refs.contactUsCardContainer.style.scrollbarColor = "var(--black) var(--white)"
    } else if (this.isMobile() && innerWidth < 768) {
      this.shouldShowDots = true
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
}

</script>

<style>

.contact-us-outer-container {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  min-width: 320px;
  max-height: 1000px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  transition: height 1000ms ease;
}
.contact-us-inner-container {
  opacity: 0;
  min-height: 600px;
  min-width: 320px;
  max-height: 1000px;
  max-width: 2000px;
  background-color: var(--white);
  width: 100%;
  height: 100%;
  transition: opacity 500ms ease, height 1000ms ease;
}
.contact-us-inner-container[active] {
  opacity: 1;
}
.contact-us-top-container {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  box-sizing: border-box;
  border-bottom: solid var(--dodger-blue) 10px;
}

.contact-us-title {
  color: var(--white);
  font-family: "Bebas neue", "sans-serif";
  font-size: 32px;
  line-height: 28px;
  @media (--large) {
    line-height: 36px;
    font-size: 42px;
  }
}

.contact-us-bottom-container {
  width: 100%;
  height: 80%;
  display: flex;
  background-color: var(--light-grey);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.contact-us-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.contact-us-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
  height: auto;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  @media (--medium) {
    width: auto;
    height: auto;
  }
}
.contact-us-card {
  scroll-snap-align: center;
  margin: 5px;
  border-radius: 10px;
  width: 310px;
  height: 400px;
  background-color: var(--white);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--medium) {
    width: 220px;
    height: 300px;
  }
  @media (--large) {
    width: 310px;
    height: 400px;
  }
}

.contact-us-address-title {
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  @media (--medium) {
    font-size: 22px;
  }
  @media (--large) {
    font-size: 32px;
  }
}

.contact-us-address-logo {
  margin-top: 5px;
  width: 40px;
  height: auto;
}

.contact-us-address {
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  width: 80%;
  @media (--medium) {
    font-size: 12px;
  }
  @media (--large) {
    font-size: 16px;
  }
}

.contact-us-address-button {
  font-family: "Bebas Neue", sans-serif;
  color: var(--white);
  background-color: black;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 200ms ease, scale 200ms ease;
  @media (--large) {
    &:hover {
      scale: 1.2;
      background-color: var(--dodger-blue);
    }
  }
}

.contact-us-address-button[clicked] {
  animation: clickAnimation 300ms ease;
}

.contact-us-cs-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  width: 90%;
  @media (--medium) {
    width: 80%;
    font-size: 22px;
  }
  @media (--large) {
    font-size: 32px;
    width: 90%;
  }
}

.contact-us-cs-logo {
  margin-top: 5px;
  width: 40px;
  height: auto;
}

.contact-us-whatsapp {
  margin-top: 5px;
  text-align: center;
  width: 80%;
  font-size: 16px;
  @media (--medium) {
    font-size: 12px;
  }
  @media (--large) {
    font-size: 16px;
  }
}

.contact-us-phone {
  margin-top: 5px;
  text-align: center;
  width: 80%;
  font-size: 16px;
  @media (--medium) {
    font-size: 12px;
  }
  @media (--large) {
    font-size: 16px;
  }
}

.contact-us-phone-number {
  text-align: center;
  width: 80%;
  font-size: 16px;
  @media (--medium) {
    font-size: 12px;
  }
  @media (--large) {
    font-size: 16px;
  }
}

.contact-us-whatsapp-number {
  text-align: center;
  width: 80%;
  font-size: 16px;
  @media (--medium) {
    font-size: 12px;
  }
  @media (--large) {
    font-size: 16px;
  }
}

.contact-us-wa-button {
  font-family: "Bebas Neue", sans-serif;
  color: var(--white);
  background-color: black;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 200ms ease, scale 200ms ease;
  @media (--large) {
    &:hover {
      scale: 1.2;
      background-color: var(--dodger-blue);
    }
  }
}

.contact-us-wa-button[clicked] {
  animation: clickAnimation 300ms ease;
}

.contact-us-email-title {
  margin-top: -13px;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  @media (--medium) {
    font-size: 22px;
  }
  @media (--large) {
    font-size: 32px;
  }
}

.contact-us-email-logo {
  margin-top: 5px;
  width: 40px;
  height: auto;
}

.contact-us-email {
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
  @media (--medium) {
    font-size: 12px;
  }
  @media (--large) {
    font-size: 16px;
  }
}
.contact-us-dots-container {
  width: 60px;
  height: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.contact-us-dot {
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius:10px;
  transition: background-color 1000ms ease;
}
.contact-us-dot[active] {
  background-color: var(--dodger-blue);
}

</style>
