<template>
  <div class="image-modal-container" @click.self="close" ref="imageModal">
    <div class="promotionModalContent">
      <img class="promotionModalImage" :src="imageUrl" alt="Promotion Image">
    </div>
    <button class="promotionModalCloser" @click="close"></button>
  </div>
</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    imageUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$refs.imageModal.removeAttribute('active')
      setTimeout(() => {
        this.$emit('closeModal')
        document.body.style.overflow = ''
      }, 200)
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
      this.$refs.imageModal.setAttribute('active', '')
    }, 0)
  },
  beforeUnmount() {
    document.body.style.overflow = ''
  }
}
</script>

<style>
.image-modal-container {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: opacity 200ms ease-in-out;
}

.image-modal-container[active] {
  opacity: 1;
}

.promotionModalContent {
  background: transparent;
  width: 95%;
  height: auto;
}

.promotionModalImage {
  width: 100%;
  height: auto;
}

.promotionModalCloser {
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/cross.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 30px;
  height: 30px;
}
</style>
