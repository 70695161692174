<template>
  <div class="product-modal-container" @click.self="closeProductModal" ref="productModalContainer">
    <div class="product-modal-content-container">
      <div class="product-modal-image-container">
        <div class="product-modal-image-scroll-container" ref="scrollContainer">
          <img v-for="(img, index) in this.$props.imageUrl" :key="index" class="product-modal-image" :src="img" alt="product-image">
        </div>
        <div v-if="this.$props.imageUrl.length > 1" class="product-modal-image-button">
          <button :disabled="shouldDisableLeftArrow" class="product-modal-image-prev-button" @click="showPrevCards()" ref="prevButton"></button>
          <button :disabled="shouldDisableRightArrow" class="product-modal-image-next-button" @click="showNextCards()" ref="nextButton"></button>
        </div>
        <button v-if="this.$props.color.length !== 0" class="product-modal-show-color-modal" @click="showModal" ref="showColorModal">color list</button>
        <img v-if="this.$props.shopeeUrl" class="product-modal-shopee" ref="shopee" @click="direction(this.$props.readyMixUrl, this.$refs.shopee)" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/pngwing.com%20(5).png" alt="sp">
      </div>
      <div class="product-modal-description-container">
        <button @click="closeProductModal(true)" class="product-modal-closer" ref="productModalCloser"></button>
        <div class="product-modal-description-top-container">
          <p class="product-title">{{this.$props.name}}</p>
        </div>
        <div class="product-modal-description-bottom-container" ref="productModalDescriptionBottomContainer">
          <p class="product-modal-description">{{capitilize(this.$props.description)}}</p>
          <div class="product-modal-split-container">
            <div class="product-modal-left-container">
              <div class="product-modal-benefits-container">
                <p class="product-modal-benefits-title">Keunggulan</p>
                <p v-for="(benefit, index) in this.$props.benefits" :key="index" class="product-modal-benefits">&bull; {{capitilize(benefit)}}</p>
              </div>
            </div>
            <div class="product-modal-right-container">
              <p class="product-modal-detail-title">Data Teknis</p>
              <p class="product-modal-detail" v-for="(value, key) in filteredDetails" :key="key">
                &bull; {{ capitilize(key) }}: {{ capitilize(value) }}
              </p>
            </div>
          </div>
          <div class="product-modal-usage-container">
            <div class="product-modal-usage-title-container">
              <p class="product-modal-usage-title">Cara Pakai</p>
            </div>
            <div class="product-modal-usage-container-content">
              <p class="product-modal-usage" v-for="(value, key) in this.$props.usage" :key="key">&bull; {{capitilize(value)}}</p>
            </div>
          </div>
          <div v-if="this.$props.readyMixUrl" class="product-modal-ready-mix">
            <button class="product-modal-gdrive" @click="direction(this.$props.readyMixUrl, this.$refs.gdrive)" ref="gdrive">For READYMIX</button>
          </div>
        </div>
        <img v-if="shouldShowUpDownImage" class="product-modal-up-down-image" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down-black.png" alt="up-down">
      </div>
    </div>
    <ColorModal v-if="showColorModal" @closeModal="this.closeColorModal" :colors="this.$props.color"></ColorModal>
  </div>
</template>

<script>

import ColorModal from "@/components/modal/color_modal/ColorModal.vue";

export default {
  name: 'ProductModal',
  components: {ColorModal},
  data() {
    return {
      showColorModal: false,
      shouldShowUpDownImage: false,
      cardWidth: 300,
      isScrolling: false,
      shouldDisableRightArrow: false,
      shouldDisableLeftArrow: true,
      imageIsLoading: false
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    imageUrl: {
      type: Array,
      required: true
    },
    description: {
      type: String
    },
    benefits: {
      type: Array
    },
    detail: {
      type: Object
    },
    usage: {
      type: Array
    },
    color: {
      type: Array
    },
    readyMixUrl: {
      type: String
    },
    shopeeUrl: {
      type: String
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    window.addEventListener('resize', this.updateComponentVisibility)
    this.updateComponentVisibility()
    setTimeout(() => {
      this.$refs.productModalContainer.setAttribute('active', '')
      if (this.$props.imageUrl.length <= 1 && window.innerWidth < 768) {
        this.$refs.scrollContainer.style.marginTop = 'unset'
      }
    }, 0)
    setTimeout(() => {
      this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
    }, 100)
  },
  beforeUnmount() {
    document.body.style.overflow = ''
    window.removeEventListener('resize', this.updateComponentVisibility)
  },
  computed: {
    filteredDetails() {
      return Object.fromEntries(
          Object.entries(this.$props.detail).filter(([key, value]) => key.trim() !== '' && value.trim() !== '')
      );
    }
  },
  methods: {
    closeProductModal(fromButton = false) {
      if (fromButton === true) {
        this.$refs.productModalCloser.setAttribute('clicked', '')
        setTimeout(() => {
          this.$refs.productModalContainer.removeAttribute('active')
          this.$refs.productModalCloser.removeAttribute('clicked')
          setTimeout(() => {
            this.$emit('closeModal')
            document.body.style.overflow = ''
          }, 200)
        }, 200)
      } else {
        this.$refs.productModalContainer.removeAttribute('active')
        setTimeout(() => {
          this.$emit('closeModal')
          document.body.style.overflow = ''
        }, 200)
      }
    },
    updateComponentVisibility() {
      const container = this.$refs.productModalDescriptionBottomContainer
      if (container) {
        this.shouldShowUpDownImage = container.scrollHeight > container.clientHeight
      }
      if (window.innerHeight <= 455) {
        this.closeProductModal()
      }
      if (this.$props.imageUrl.length <= 1 && window.innerWidth < 768) {
        this.$refs.scrollContainer.style.marginTop = 'unset'
      }
    },
    capitilize(text) {
      const cleanText = text.replace(/_/g, ' ');
      const words = cleanText.split(' ');
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
      return words.join(' ');
    },
    showModal() {
      this.$refs.showColorModal.setAttribute('clicked', '')
      setTimeout(() => {
        this.$refs.showColorModal.removeAttribute('clicked')
        this.showColorModal = true
      }, 300)
    },
    closeColorModal() {
      this.showColorModal = false
    },
    direction(url, ref) {
      ref.setAttribute('clicked', '')
      setTimeout(() => {
        ref.removeAttribute('clicked')
        window.open(url)
      }, 300)
    },
    showNextCards() {
      if (this.isScrolling) return;

      this.isScrolling = true;
      this.$refs.nextButton.setAttribute('clicked', '')

      this.$refs.scrollContainer.scrollBy({
        left: this.cardWidth,
        behavior: 'smooth'
      });

      setTimeout(() => {
        this.isScrolling = false;
        this.$refs.nextButton.removeAttribute('clicked')
      }, 300);
    },
    showPrevCards() {
      if (this.isScrolling) return;

      this.isScrolling = true;
      this.$refs.prevButton.setAttribute('clicked', '')

      this.$refs.scrollContainer.scrollBy({
        left: -this.cardWidth,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.isScrolling = false;
        this.$refs.prevButton.removeAttribute('clicked')
      }, 300);
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const maxScrollLeft = (container.scrollWidth - container.clientWidth) - 100;

      this.shouldDisableRightArrow = container.scrollLeft >= maxScrollLeft;
      this.shouldDisableLeftArrow = container.scrollLeft < 100;
    }
  }
}
</script>

<style>
.product-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.product-modal-container[active] {
  opacity: 1;
}
.product-modal-content-container {
  box-sizing: border-box;
  background-color: var(--light-grey);
  max-width: 1000px;
  max-height: 600px;
  min-width: 300px;
  min-height: 450px;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border: solid var(--white) 3px;
  @media (--medium) {
    min-width: 720px;
    min-height: 450px;
    width: 60%;
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 100;
    border: solid var(--white) 12px;
  }
}
.product-modal-image-container {
  width: 100%;
  height: 50%;
  background-color: var(--light-grey);
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (--medium) {
    width: 50%;
    height: 100%;
  }
}
.product-modal-image-scroll-container {
  position: relative;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin-top: 10px;
  gap: 30px;
  scroll-snap-type: x mandatory;
  box-sizing: border-box;
  @media (--medium) {
    margin-top: unset;
    width: 340px;
    height: 380px;
  }
}
.product-modal-image {
  opacity: 1;
  scroll-snap-align: center;
  width: 100%;
  height: auto;
  max-height: 200px;
  @media (--medium) {
    max-height: unset;
  }
}
.product-modal-image:first-of-type {
  margin-left: 30px;
}
.product-modal-image:last-of-type {
  margin-right: 30px;
}
.product-modal-image-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
  @media (--medium) {
    margin-top: 15px;
    margin-bottom: unset;
  }
}
.product-modal-image-prev-button {
  background-color: transparent;
  border: none;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-95-512.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
  will-change: transform;
  transition: scale 300ms ease, filter 1000ms ease;
  @media (--medium) {
    &:hover {
      scale: 1.1;
    }
  }
  &:disabled {
    cursor: default;
    filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(125%) hue-rotate(314deg) brightness(92%) contrast(85%);
  }
}
.product-modal-image-prev-button[clicked] {
  animation: clickAnimation 300ms ease;
}
.product-modal-image-next-button {
  background-color: transparent;
  border: none;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-30-512.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
  will-change: transform;
  transition: scale 300ms ease, filter 1000ms ease;
  @media (--medium) {
    &:hover {
      scale: 1.1;
    }
  }
  &:disabled {
    cursor: default;
    filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(125%) hue-rotate(314deg) brightness(92%) contrast(85%);
  }
}
.product-modal-image-next-button[clicked] {
  animation: clickAnimation 300ms ease;
}
.product-modal-show-color-modal {
  position: absolute;
  bottom: 10px;
  left: 7px;
  background-color: black;
  color: var(--white);
  width: 80px;
  height: 20px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  border: solid var(--dodger-blue) 2px;
  border-radius: 10px;
  cursor: pointer;
  transition: scale 300ms ease;
  font-size: 11px;
  will-change: transform;
  @media (--medium) {
    left: 10px;
    font-size: unset;
    width: 100px;
    height: 25px;
    &:hover {
      scale: 1.05;
    }
  }
}
.product-modal-show-color-modal[clicked] {
  animation: clickAnimation 300ms ease;
}
.product-modal-description-container {
  width: 100%;
  height: 50%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (--medium) {
    width: 50%;
    height: 100%;
  }
}
.product-modal-description-top-container {
  height: 23%;
  width: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: solid var(--dodger-blue) 5px;
  position: relative;
  @media (--medium) {
    height: 15%;
  }
}
.product-title {
  font-size: 22px;
  color: var(--white);
  width: 80%;
  @media (--medium) {
    font-size: 28px;
  }
}
.product-modal-description-bottom-container {
  width: 100%;
  height: 77%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 10px;
  @media (--medium) {
    height: 85%;
  }
}
.product-modal-description {
  color: var(--white);
  font-size: 14px;
  width: 90%;
  margin-top: 10px;
}
.product-modal-split-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
}
.product-modal-left-container {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.product-modal-right-container {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  box-sizing: border-box;
}
.product-modal-detail-title {
  margin-top: 20px;
  color: var(--white);
  margin-left: 10%;
  border-bottom: solid var(--dodger-blue) 1px;
  margin-bottom: 3px;
  padding-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
}
.product-modal-detail {
  color: var(--white);
  line-height: 20px;
  font-size: 14px;
  margin-left: 13.5%;
  text-indent: -8px;
}
.product-modal-benefits-container {
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10%;
  padding-right: 10px;
  box-sizing: border-box;
}
.product-modal-benefits-title {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 3px;
  width: fit-content;
  border-bottom: solid var(--dodger-blue) 1px;
  margin-bottom: 3px;
}
.product-modal-benefits {
  line-height: 20px;
  color: var(--white);
  font-size: 14px;
  text-indent: -8px;
  margin-left: 7px;
}
.product-modal-usage-container {
  margin-top: 15px;
  height: auto;
  width: 100%;
  border-top: solid var(--white) 1px;
  box-sizing: border-box;
  padding-right: 14px;
}
.product-modal-usage-container-content {
  margin-top: 3px;
  margin-left: 5%;
}
.product-modal-usage-title-container {
  width: 100%;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-modal-usage-title {
  margin-top: 7px;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  padding-bottom: 3px;
  border-bottom: var(--dodger-blue) solid 1px;
}
.product-modal-usage {
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
  text-indent: -8px;
  margin-left: 7px;
}
.product-modal-closer {
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/x-button.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 18px;
  height: 18px;
  margin-top: 10px;
  position: absolute;
  right: 2px;
  top: -7px;
  border: solid black 3px;
  border-radius: 3px;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
  z-index: 1000;
  @media (--medium) {
    right: 4px;
    top: -5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover {
      background-color: var(--dodger-blue);
      border: solid var(--dodger-blue) 3px;
    }
  }
}
.product-modal-closer[clicked] {
  animation: clickAnimation 200ms ease;
}
.product-modal-up-down-image {
  opacity: 1;
  position: absolute;
  width: 16px;
  height: 17px;
  bottom: 2px;
  right: -2px;
  filter: invert(1) brightness(2);
  @media (--medium) {
    bottom: 2px;
    right: -3px;
    width: 20px;
    height: 20px;
  }
}
.product-modal-gdrive {
  margin-bottom: 0;
  color: var(--black);
  width: 90px;
  height: 25px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  transition: scale 300ms ease;
  font-size: 11px;
  background-color: var(--dodger-blue);
  will-change: transform;
  @media (--medium) {
    font-size: 13px;
    width: 120px;
    height: 30px;
    &:hover {
      scale: 1.05;
    }
  }
}
.product-modal-gdrive[clicked] {
  animation: clickAnimation 300ms ease;
}
.product-modal-ready-mix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-top: solid var(--white) 2px;
  box-sizing: border-box;
  padding-top: 9px;
}
.product-modal-shopee {
  position: absolute;
  bottom: 8px;
  right: 4px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--medium) {
    width: 60px;
    height: 60px;
    right: 6px;
    bottom: 6px;
    &:hover{
      scale: 1.05;
    }
  }
}
.product-modal-shopee[clicked] {
  animation: clickAnimation 300ms ease;
}
.product-modal-image-overlay {
  opacity: 0;
  position: absolute;
  transition: opacity 300ms ease;
}
.product-modal-image-overlay[active] {
  opacity: 1;
}
</style>
