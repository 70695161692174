<template>
  <div class="footer-container">
    <div class="footer-top-container">
      <div class="footer-left-container">
        <img src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/stiker%20mobil%20aris.png" alt="bukan-kaleng-kaleng" class="footer-image-conditional">
        <p class="footer-title">PT DUMONS CAT INDONESIA</p>
        <p class="footer-address">Jl Raya Boyolali - Semarang km 0.2 Winong, Boyolali, Jawa Tengah, Indonesia 57315</p>
        <p class="footer-address">Whatsapp - 085159122501</p>
        <p class="footer-address">dumonscoatingindonesia@gmail.com</p>
      </div>
      <div class="footer-right-container">
        <img src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/stiker%20mobil%20aris.png" alt="bukan-kaleng-kaleng" class="footer-image">
      </div>
    </div>
    <div class="footer-bottom-container">
      <p>&copy; All Rights Reserved</p>
      <p class="footer-notes">AKURASI WARNA: Kami berusaha menampilkan warna layar seakurat mungkin, namun tidak menjamin kesamaan dengan warna produk sebenarnya. Kami sarankan merujuk pada Kartu Warna di toko cat atau bahan bangunan terdekat.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPage'
}
</script>

<style>
.footer-container {
  width: 100%;
  height: 300px;
  min-width: 320px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Bebas Neue", sans-serif;
  @media (--large) {
    height: 220px;
  }
}
.footer-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80%;
  @media (--large) {
    flex-direction: row;
  }
}
.footer-bottom-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  color: var(--white);
}
.footer-left-container {
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  @media (--large) {
    margin-left: 100px;
    align-items: flex-start;
    width: 50%;
    padding-right: 70px;
  }
}
.footer-right-container {
    display: none;
    @media (--large) {
      width: 50%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
}
.footer-title {
  font-size: 24px;
  color: var(--white);
  @media (--large) {
    font-size: 38px;
    width: unset;
  }
}
.footer-address {
  text-align: left;
  color: var(--white);
  font-size: 12px;
  @media (--large) {
    font-size: 18px;
  }
}
.footer-image {
  height: 85%;
  width: auto;
  margin-right: 103px;
}
.footer-image-conditional {
  width: 200px;
  height: auto;
  @media (--large) {
    display: none;
  }
}
.footer-notes {
  width: 65%;
  font-size: 8px;
  text-align: center;
  color: var(--white);
  @media (--large) {
    width: 70%;
    font-size: 12px;
  }
}
</style>
