<template>
  <section class="color-modal-container" ref="colorModalContainer" @click.self="close">
    <div class="color-modal-content-container">
      <div class="color-modal-scroll-container" ref="scrollContainer">
        <div class="color-modal-list-container">
          <p class="color-list" v-for="(color, index) in this.$props.colors" :key="index">{{color}}</p>
        </div>
      </div>
      <img v-if="isOverflowingDescription" class="color-modal-up-down-image" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down-black.png" alt="up-down">
    </div>
    <div class="color-modal-closer-container">
      <div class="color-modal-closer" @click="close(true)" ref="colorModalCloser"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ColorModal',
  data() {
    return {
      isOverflowingDescription: false,
    }
  },
  props: {
    colors: {
      type: Array,
      required: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateComponentVisibility)
    this.updateComponentVisibility()
    setTimeout(() => {
      this.$refs.colorModalContainer.setAttribute('active', '')
    },)
  },
  methods: {
    close(fromButton = false) {
      if (fromButton === true) {
        this.$refs.colorModalCloser.setAttribute('clicked', '')
        setTimeout(() => {
          this.$refs.colorModalContainer.removeAttribute('active')
          this.$refs.colorModalCloser.removeAttribute('clicked')
          window.removeEventListener('resize', this.updateComponentVisibility)
          setTimeout(() => {
            this.$emit('closeModal')
          }, 200)
        }, 200)
      } else {
          this.$refs.colorModalContainer.removeAttribute('active')
          window.removeEventListener('resize', this.updateComponentVisibility)
          setTimeout(() => {
            this.$emit('closeModal')
          }, 200)
      }
    },
    updateComponentVisibility() {
      const colorScrollContainer = this.$refs.scrollContainer
      if (colorScrollContainer) {
        this.isOverflowingDescription = colorScrollContainer.scrollHeight > colorScrollContainer.clientHeight
      }
      if (window.innerHeight <= 455) {
        this.close()
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateComponentVisibility)
  }
}
</script>

<style>
.color-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.color-modal-container[active] {
  opacity: 1;
}
.color-modal-content-container {
  position: relative;
  width: 300px;
  height: 445px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border: 10px solid var(--grey);
}
.color-modal-scroll-container {
  width: 100%;
  height: auto;
  max-height: 97%;
  overflow-y: scroll;
  position: relative;
}
.color-modal-list-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white);
  width: auto;
  max-height: 1500px;
  height: auto;
  padding-right: 13px;
  padding-left: 13px;
  gap: 10px;
  position: relative;
}

.color-list {
  background-color: black;
  color: var(--white);
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  line-height: 1.25;
  margin: 0;
  height: fit-content;
  width: 110px;
}
.color-modal-up-down-image {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 3px;
  right: -1px;
}
.color-modal-closer {
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/cross.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: var(--white);
  border-radius: 20px;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--medium) {
    &:hover {
      scale: 1.2;
    }
  }
}
.color-modal-closer[clicked] {
  animation: clickAnimation 200ms ease;
}
.color-modal-closer-container {
  width: 300px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
</style>
