<template>
  <div ref="testimonialContainer" class="testimonial-container">
    <div class="testimonial-top-container">
      <p class="testimonial-header">See the Results!</p>
      <p class="testimonial-top-text">Real Transformations with Our Coating</p>
    </div>
    <div class="testimonial-bottom-container">
      <div class="testimonial-card-container">
        <div
            v-for="(card, index) in visibleArray"
            :key="index"
            class="testimonial-card-wrapper"
        >
          <img
              class="testimonial-card"
              :src="card.data.imageUrl"
              alt="img"
              @click="showModal(card.data.imageUrl)"
          />
          <div class="hover-box">
            <p>{{ card.data.tag }}</p>
          </div>
        </div>
      </div>
      <div class="testimonial-button-container">
        <button class="testimonial-prev-button" @click="showPrevCards(true)" ref="prevButton"></button>
        <button class="testimonial-next-button" @click="showNextCards(true)" ref="nextButton"></button>
      </div>
    </div>
  </div>
  <ImageModal
      v-if="this.selectedTestimonial"
      :image-url="selectedTestimonial"
      @closeModal="closeModal"/>
</template>
<script>

import ImageModal from "@/components/modal/image_modal/ImageModal.vue";

function testimonialCard(imageUrl, tag) {
  return {
    data : {
      imageUrl: imageUrl,
      tag: tag,
    }
  }
}

export default {
  name: "TestimonialPage",
  components: {
    ImageModal,
  },
  data () {
    return {
      testimonials : [
        testimonialCard("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg", 'Clear S2 @ Kediri'),
        testimonialCard("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.23.jpeg", 'PU 2K Surfacer @ Surabaya'),
        testimonialCard("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.21.jpeg", 'PP Primer Black @ Jogja'),
        testimonialCard("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(1).jpeg", 'Clear 705 @ Wonogiri'),
        testimonialCard("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(2).jpeg", 'Epoxy Primer Green @ Wonogiri'),
        testimonialCard('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/Untitled-1.png', 'Clear S1 @ Klaten')
      ],
      visibleArray: [],
      startIndex: 0,
      cardUpdated: false,
      intervalId: null,
      selectedTestimonial: null,
      overPref: 0,
      overNext: 0,
      disableClick: false,
    }
  },
  mounted() {
    const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
    setTimeout(() => {
      if (window.innerWidth < 768) {
        this.cardUpdated = true
        this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
        this.intervalId = setInterval(() => {
          this.startIndex = (this.startIndex + 1) % this.testimonials.length
          this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
        }, 5000);
      } else {
        this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
        this.intervalId = setInterval(() => {
          this.showNextCards()
        }, 5000);
      }
    }, timeout)
    window.addEventListener('resize', this.setVisibleArray)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setVisibleArray)
  },
  methods: {
    showNextCards(shouldClearInterval) {
      if (this.disableClick === true) return
      if (shouldClearInterval) {
        this.disableClick = true
        clearInterval(this.intervalId);
        this.$refs.nextButton.setAttribute('clicked', '')
        setTimeout(() => {
          this.$refs.nextButton.removeAttribute('clicked')
          this.disableClick = false
        },200)
      }
      if (window.innerWidth < 768) {
         if (this.startIndex === this.testimonials.length - 1) {
           this.startIndex = 0
           this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
         } else {
           this.startIndex++
           this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
        }
      } else {
        if (this.startIndex + 3 < this.testimonials.length) {
          if (this.overPref === -1){
            this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
            this.overPref++
          } else if (this.overPref === -2) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 1],
              this.testimonials[0],
              this.testimonials[1]
            ]
            this.overPref++
          } else {
            this.startIndex++
            this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
          }
        } else {
          if (this.overNext === 0) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 2],
              this.testimonials[this.testimonials.length - 1],
              this.testimonials[this.overNext],
            ]
            this.overNext ++
          } else if (this.overNext === 1) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 1],
              this.testimonials[this.overNext - 1],
              this.testimonials[this.overNext],
            ]
            this.overNext ++
          } else if (this.overNext === 2) {
            this.overNext = 0
            this.startIndex = 0
            this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
          }
        }
      }
    },
    showPrevCards(shouldClearInterval) {
      if (this.disableClick === true) return
      if (shouldClearInterval) {
        this.disableClick = true
        clearInterval(this.intervalId);
        this.$refs.prevButton.setAttribute('clicked', '')
        setTimeout(() => {
          this.$refs.prevButton.removeAttribute('clicked')
          this.disableClick = false
        },200)
      }
      if (window.innerWidth < 768) {
        if (this.startIndex === 0) {
          this.startIndex = this.testimonials.length - 1
          this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
        } else {
          this.startIndex--
          this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
        }
      } else {
        if (this.startIndex === 0) {
          if (this.overPref === 0) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 1],
              this.testimonials[this.startIndex],
              this.testimonials[this.startIndex + 1]
            ]
            this.overPref--
          } else if (this.overPref === -1) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 2],
              this.testimonials[this.testimonials.length - 1],
              this.testimonials[this.startIndex],
            ]
            this.overPref--
          } else if (this.overPref === -2) {
            this.startIndex = 2
            this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
            this.overPref = 0
          }
        } else {
          if (this.overNext === 1) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 3],
              this.testimonials[this.testimonials.length - 2],
              this.testimonials[this.testimonials.length - 1],
            ]
            this.overNext--
          } else if (this.overNext === 2) {
            this.visibleArray = [
              this.testimonials[this.testimonials.length - 2],
              this.testimonials[this.testimonials.length - 1],
              this.testimonials[0],
            ]
            this.overNext--
          } else {
            this.startIndex--
            this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
          }
        }
      }
    },
    setVisibleArray() {
      const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
      setTimeout(() => {
        if (window.innerWidth < 768 && this.cardUpdated === false) {
          clearInterval(this.intervalId)
          this.cardUpdated = true
          this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
          this.intervalId = setInterval(() => {
            this.startIndex = (this.startIndex + 1) % this.testimonials.length
            this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 1)
          }, 5000);
        } else if (window.innerWidth >= 768 && this.cardUpdated === true) {
          clearInterval(this.intervalId)
          this.startIndex = 0
          this.cardUpdated = false
          this.visibleArray = this.testimonials.slice(this.startIndex, this.startIndex + 3)
          this.intervalId = setInterval(() => {
            this.showNextCards()
          }, 5000);
        }
      }, timeout)
    },
    showModal(imageUrl) {
      if (window.innerWidth <= 430 && window.innerHeight >= 600) {
        this.selectedTestimonial = imageUrl
      }
    },
    closeModal() {
      this.selectedTestimonial = null
    }
  }
}

</script>
<style>

.testimonial-container {
  width: 100%;
  max-height: 1000px;
  min-height: 600px;
  height: 100vh;
  height: 100lvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  min-width: 320px;
}
.testimonial-top-container {
  width: 100%;
  height: 25%;
  background-color: black;;
  //background: linear-gradient(to bottom, rebeccapurple, black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.testimonial-header {
  font-family: "Bebas Neue", sans-serif;
  color: var(--white);
  font-size: 40px;
  @media (--large) {
    font-size: 60px;
  }
}
.testimonial-top-text {
  color: wheat;
  font-size: 16px;
  @media (--large) {
    font-size: 22px;
  }
}
.testimonial-bottom-container {
  width: 100%;
  height: 75%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-card-container {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.testimonial-card-wrapper {
  position: relative;
  display: inline-block;
}
.testimonial-card {
  height: 320px;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  @media (--medium) {
    height: 240px;
  }
  @media (--large) {
    height: 310px;
  }
  @media (--page-max-width) {
    height: 400px;
  }
}
.hover-box {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  color: black;
  padding: 10px;
  border-radius: 5px;
  display: none;
  text-align: center;
  width: 80%;
  font-size: 14px;
  @media (--small) {
    display: block;
  }
}
.testimonial-card-wrapper:hover .hover-box {
  @media (--medium) {
    display: block;
  }
}
.testimonial-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10%;
  width: 80px;
}
.testimonial-prev-button {
  left: 0;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-95-512.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover {
      scale: 1.1;
    }
  }
}
.testimonial-prev-button[clicked] {
  animation: clickAnimation 200ms ease;
}
.testimonial-next-button {
  right: 0;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-30-512.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 20px;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover {
      scale: 1.1;
    }
  }
}
.testimonial-next-button[clicked] {
  animation: clickAnimation 200ms ease;
}
</style>
