<template>
  <div ref="socialMediaContainer" class="social_media_container">
    <div class="social_media_image_container">
      <div class="social_media_SP_logo">
        <img ref="sp" @click="redirection('https://shopee.co.id/dumonsindonesia', this.$refs.sp)" class="social_media_shopee" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/pngwing.com%20(5).png" alt="sp"/>
      </div>
      <div class="social_media_content_container">
        <img  src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/medsos.png" alt="sosmed" class="social_media_image_logo">
        <div class="social_media_logo_container">
          <div ref="ig" @click="redirection('https://www.instagram.com/dumons.id?igsh=MWRxZW9ibTFnMm55NQ==', this.$refs.ig)" class="social_media_IG_logo"></div>
          <div ref="tt" @click="redirection('https://www.tiktok.com/@dumons.id?_t=8qa0g3A4H90&_r=1', this.$refs.tt)" class="social_media_TT_logo"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SocialMediaSection',
    mounted() {
    },
    methods: {
      redirection(redirectUrl, ref) {
        ref.setAttribute('clicked', '')
        setTimeout(() => {
          window.open(redirectUrl)
          ref.removeAttribute('clicked')
        }, 300)
      }
    }
  }
</script>
<style>
.social_media_container {
  width: 100%;
  max-height: 1000px;
  min-height: 600px;
  height: 100vh;
  height: 100lvh;
  display: flex;
  align-items: center;
  background-color: black;
  min-width: 320px;
}
.social_media_image_container {
  width: 100%;
  height: 100%;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg");
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: bottom;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  @media (--large) {
    width: 70%;
  }
}
.social_media_content_container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--large) {
    margin-left: auto;
    margin-right: -260px;
  }
}
.social_media_image_logo {
  width: 315px;
  height: auto;
  @media (--large) {
    width: 450px;
  }
}
.social_media_logo_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  @media (--large) {
    justify-content: flex-end;
  }
}
.social_media_IG_logo {
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/instagram.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 40px;
  width: 40px;
  margin-right: 5px;
  cursor: pointer;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    height: 50px;
    width: 50px;
    &:hover {
      scale: 1.1;
    }
  }
}
.social_media_IG_logo[clicked] {
  animation: clickAnimation 300ms ease;
}
.social_media_TT_logo {
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/tik-tok.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 40px;
  width: 40px;
  margin-left: 5px;
  cursor: pointer;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    background-color: unset;
    margin-left: 10px;
    height: 50px;
    width: 50px;
    &:hover {
      scale: 1.1;
    }
  }
}
.social_media_TT_logo[clicked] {
  animation: clickAnimation 300ms ease;
}
.social_media_SP_logo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 20px;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--large) {
    height: 120px;
    width: 120px;
  }
}
.social_media_shopee {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover {
      scale: 1.1;
    }
  }
}

.social_media_shopee[clicked] {
  animation: clickAnimation 300ms ease;
}

</style>
