<template>
  <div class="landing-page-container" ref="landingPageContainer">
      <HomePage />
      <PromotionPage />
      <ProductHighlightPage/>
      <TestimonialPage/>
      <SocialMediaSection/>
  </div>
</template>

<script>
import HomePage from '@/components/home/HomePage.vue'
import PromotionPage from "@/components/promotion/PromotionPage.vue";
import ProductHighlightPage from "@/components/product_highlight/ProductHighlightPage.vue";
import TestimonialPage from "@/components/testimonial/TestimonialPage.vue";
import SocialMediaSection from "@/components/social_media/SocialMediaSection.vue";

export default {
  name: 'App',
  components: {
    SocialMediaSection,
    ProductHighlightPage,
    HomePage,
    PromotionPage,
    TestimonialPage,
  },
  mounted() {
    this.scrollToTop()
    setTimeout(() => {
      this.$refs.landingPageContainer.setAttribute('active', '')
    }, 0)
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style>
.landing-page-container {
  opacity: 0;
  transition: opacity 500ms ease;
}
.landing-page-container[active] {
  opacity: 1;
}
</style>
