import { createWebHistory, createRouter } from 'vue-router';
import LandingPage from '@/components/landing/LandingPage.vue';
import NotFoundPage from '@/components/404/NotFoundPage.vue';
import ProductListPage from "@/components/productList/ProductList.vue";
import AboutUsPage from "@/components/about_us/AboutUsPage.vue";
import SocialMediaPage from "@/components/social_media/SocialMediaPage.vue";
import ContactUsPage from "@/components/contact_us/ContactUsPage.vue";

// Define routes
const routes = [
    { path: '/', redirect: '/home' },
    { path: '/home', component: LandingPage },
    { path: '/404', component: NotFoundPage },
    { path: '/productList', component: ProductListPage},
    { path: '/aboutUs', component: AboutUsPage},
    { path: '/socialMedia', component: SocialMediaPage },
    { path: '/contactUs', component: ContactUsPage},
    { path: '/:pathMatch(.*)*', redirect: '/404' },
];

// Create router
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

export default router;
